import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const OmegaSeamaster = () => {
  const data = useStaticQuery(
    graphql`
      query queryOmegaSeamaster {
        products: allStrapiProduct(
          filter: { brand: { eq: "Omega" }, model: { eq: "Seamaster" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Omega Seamaster Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/omega/seamaster/'}
      description={
        ' Grays & Sons are experts in buying and selling second hand Omega Seamaster Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/omega/seamaster/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-omega-seamaster-header.png"
                alt="Pre-Owned Certified Used Omega Seamaster Watches Header"
                aria-label="Used Omega Seamaster Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Omega Seamaster</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE OMEGA SEAMASTER WATCHES AT
              GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED OMEGA SEAMASTER WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              Many people online are looking for a reputable place to ‘sell my Omega Seamaster
              watch’ online or in person. Gray and Sons Jewelers will buy your Omega Seamaster watch
              right now for cash in person or online at
              <a href="https://sellusyourjewelry.com"> www.sellusyourjewelry.com</a>
              . For over 42 years, Gray and Sons Jewelers has been in the business of buying,
              selling, trading, and repairing genuine Omega watches. In our store, we carry six
              Master-trained watchmakers with over 150 years of combined experience in the
              overhauling and detailing of these timepieces. Aside from the Omega Seamaster, we also
              work with other Omega watch model collections such as the Omega Speedmaster, Omega
              Constellation, Omega Seamaster Diver, Omega De Ville, and many more!
              <br />
              <br />
              <b>About Pre-Owned Omega Seamaster Watches at Gray and Sons</b>
              <br />
              <br />
              At a glance, if one didn’t know any better they’d think the Omega Seamaster collection
              consisted of different watch models. Since 1948, Omega has been releasing several
              looks and models in the Seamaster Collection inspired by all different kinds of
              styles. Whether preferring a sports style, dress watch, classic timepiece, or
              vintage-inspired wristwatch, Omega has designed a reference to meet all consumer
              needs.
              <br />
              <br />
              Within the entire Omega Seamaster Collection, there are sub-collections they are
              categorized into. Each sub-collection not only brings a different aesthetic to the
              collection, but each serves a function unique to its reference. The sub-collections go
              as follows:
              <ul>
                <li>Omega Seamaster Ploprof 1200M</li>
                <li>Omega Seamaster Planet Ocean 600m</li>
                <li>Omega Seamaster Diver 300M</li>
                <li>Omega Seamaster Aqua Terra 150M</li>
                <li>Omega Seamaster Bullhead</li>
                <li>Omega Seamaster Railmaster</li>
                <li>Omega Seamaster 300</li>
                <li>Omega Seamaster 1948</li>
              </ul>
              <br />
              <br />
              There's a reason Omega is one of the top name watch brands around the world. Being the
              official timekeepers for the Olympics and Nasa, Omega prides itself on being the world
              standard for their pristine watchmaking skills.
              <br />
              <br />
              <b>Buy Pre-Owned Omega Seamaster Watches</b>
              <br />
              <br />
              There is plenty of variety within second hand Omega Seamaster watches, including
              various different sizes, styles, and complications. From simpler time-only models to
              complicated variants featuring co-axial movements; if you’re in the market to buy a
              certified pre-owned Omega Seamaster watch, then look no further than Gray &amp; Sons.
              Make a deal today with one of our decision-makers today to purchase the pre owned
              Omega Seamaster watch of your dreams. We offer certified pre-owned watches.
              <br />
              <br />
              <b>Sell Used Omega Seamaster Watches</b>
              <br />
              <br />
              On the other hand, if you’ve recently thought to yourself, I want to sell my Omega
              Seamaster watch for cash, reach out to Gray &amp; Sons at{' '}
              <a href="/">www.grayandsons.com</a>.or visit{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com</a>. We have real
              Omega watch specialists who can answer all of your questions. Reach out to our expert
              Omega specialist at <a href="/chat-with-rich">www.grayandsons.com/chat-with-rich</a>
              .to get the best cash offer available in the market. Popular Pre-Owned Omega Seamaster
              Reference Numbers are:
              <ul>
                <li>Ref. 232.30.42.21.01.003</li>
                <li>Ref. 232.30.38.20.01.002</li>
                <li>Ref. 2200.50</li>
                <li>Ref. 212.32.41.20.04.001</li>
                <li>Ref. 212.30.42.50.01.001</li>
                <li>Ref. 210.90.42.20.01.001</li>
                <li>Ref. 210.30.42.20.04.001</li>
              </ul>
              <br />
              <br />
              <b>Service Pre-Owned Omega Seamaster Watches</b>
              <br />
              <br />
              Gray &amp; Sons boasts a full-service Omega Seamaster watch repair shop on-site with a
              team of highly-trained skilled watchmakers. Due to their detailed workmanship and
              Swiss-made movements, the servicing and repair of second hand Omega Seamaster watches
              should only be done by expert watchmakers. Whether for a complicated repair job,
              regular maintenance, or battery replacement, our customers agree that Gray &amp; Son’s
              independent Omega watch repair center is the best in Miami.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/omega/omega-seamaster/">
                <button>SHOP OMEGA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK OMEGA WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default OmegaSeamaster
